<template lang="pug">
#user-info.step-component
  h2 Please enter your info

  .form-field(:class="{ 'field-error': $v.form.fullName.$error }")
    label Full Name
    input.input(type="text", v-model.trim="form.fullName", placeholder="Type your name")
    .form-error(v-if="$v.form.fullName.$error") This field is required

  .form-field(v-if="!hasOldBoxId" :class="{ 'field-error': $v.form.birthDate.$error }")
    label Birth Date
    input.input(type="date" v-model="form.birthDate")
    .form-error(v-if="$v.form.birthDate.$error") This field is required

  .form-field(:class="{ 'field-error': $v.form.gender.$error }")
    label(for="gender") Sex
    select#gender(name="gender", v-model="form.gender")
      option(value="Male") Male
      option(value="Female") Female
    .form-error(v-if="$v.form.gender.$error") Please select an option from the list

  .form-field(:class="{ 'field-error': $v.form.lastMeal.$error }")
    label(for="lastmeal") Hours Since Last Meal
    input(type="number", name="lastmeal", min="1", max="99", step="1", v-model="form.lastMeal")
    .input-suffix {{ hoursSuffix }}
    .form-error(v-if="$v.form.lastMeal.$error") This field is required

  VerificationButton.button(
    @click.native="submit()",
    text="Confirm",
    :state="verificationButtonState"
  )
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'

export default {
  props: {},

  data() {
    return {
      form: {
        fullName: '',
        birthDate: '',
        gender: '',
        lastMeal: '',
      },
      heightMask: '#\'##?"',
      verificationButtonState: 'init',
      hasOldBoxId: false,
    }
  },

  mounted() {
    const barcodeRegexp = new RegExp(/^\d{6}$/)
    if (barcodeRegexp.test(this.barcodeId)) {
      this.hasOldBoxId = true
    }
  },

  validations() {
    if (this.hasOldBoxId) {
      return {
        form: {
          fullName: { required },
          gender: { required },
          lastMeal: { required },
        },
      }
    } else {
      return {
        form: {
          fullName: { required },
          birthDate: { required },
          gender: { required },
          lastMeal: { required },
        },
      }
    }
  },

  computed: {
    ...mapGetters({
      barcodeId: 'user/getBarcodeId',
      phone: 'user/getPhone'
    }),
    hoursSuffix,
  },

  watch: {},

  methods: {
    submit,
  },

  components: {
    VerificationButton: require('@/components/VerificationButton').default,
  },
}

/* Computed ---------------------------------------------------- */
function hoursSuffix() {
  return this.form.lastMeal == '' ? '' : this.form.lastMeal <= 1 ? 'hour' : 'hours'
}

/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function submit() {
  this.$v.$touch()
  if (!this.$v.$invalid) {
    this.verificationButtonState = 'try'
    this.form.phone = this.phone
    this.$store.dispatch('user/create', {
      userInfo: { deviceData: navigator?.userAgent, ...this.form },
    })
    this.verificationButtonState = 'success'

    setTimeout(() => {
      this.$emit('complete')
    }, 1000)
  }
}
</script>
